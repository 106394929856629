import { SET_PUBLIC_CARS } from "../actions";

const initialState = {
    cars: []
}

export function publicReducer(state = initialState, action){
    switch(action.type) {
        case SET_PUBLIC_CARS: 
            return {...state, cars: action.cars}
        default: return state
    }
}