import MyNavbar from "./MyNavbar"
import CarsContainer from "./CarsContainer";

function Overview(props){
    return (
        <>
        <MyNavbar />
        <CarsContainer carsPerRow={3}/>
        </>
    )
}
export default Overview;