import { SET_LOGGED_IN, SET_LOGIN_ERROR_MSG } from "../actions";

const initialState = {
    username: "",
    password: "",
    error_msg: "",
    logged_in: localStorage.getItem('logged_in') ? true : false
}

export function loginReducer(state = initialState, action){
    switch(action.type){
        case SET_LOGIN_ERROR_MSG: return {...state, error_msg: action.msg}
        case SET_LOGGED_IN: return {...state, logged_in: action.val}
        default: return state
    }
}