

export function getJWTHeader(){
    const token = localStorage.getItem('jwt-token')
    let header = 
    {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'crossDomain': true
        }
    }
    if (token != null){
        header["headers"]['Authorization'] = 'Bearer '+token
    }
    return header
}

export function handleJWTErrors(response){
    // if the jwt-token is expired...
    if(response["jwt_err"] == "jwt-token-expired"){
        // ..relog by forwarding to login-form
        
    }
}