import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import {fetchCars, editCar, setCarSold, setCarActive, setCar, autoLogin, archiveCar, toggleArchive} from '../actions'
import {connect} from 'react-redux'
import { useEffect } from 'react'
import { history } from '../Store'

function CarsContainer(props){

    useEffect(() => {
        if (props.logged_in === false) {
            props.autoLogin()
        }
    },[])

    function handleEdit(e){
        var correct_car
        for (let i = 0; i < props.cars.length; i++) {
            if(e.target.dataset.id === props.cars[i]._id){
                correct_car = props.cars[i]
            }
        }
        props.setCar(correct_car)
        history.push('/newcar')
        // forward to prefilled Input
    }

    function handleSold(e){
        props.setCarSold(e.target.dataset.id)
    }

    function handleActive(e){
        props.setCarActive(e.target.dataset.id)
    }

    function handleRemove(e){
        props.archiveCar(e.target.dataset.id)
    }

    function renderCarCard(car){
        let carClasses = ""
        let buttons = []
        let soldText = ""
        if(car["sold"] === true){
            carClasses = "sold"
            soldText = <Col><h2>Verkauft</h2></Col>
            buttons = <>
            <Col><Button variant="outline-primary" data-id={car._id} onClick={handleActive}>Aktiv setzen</Button></Col>
            <Col><Button variant="outline-danger" data-id={car._id} onClick={handleRemove}>Archivieren</Button></Col>
            </>
        } else {
            buttons = 
            <>
                <Col><Button variant="primary" data-id={car._id} onClick={handleEdit}>Bearbeiten</Button></Col>
                <Col><Button variant="danger" data-id={car._id} onClick={handleSold}>Verkauft</Button></Col>
            </>
        }
        return(
            <Col>
                <Card style={{ width: '100%' }}>
                <Card.Img variant="top" src={car["thumbnail"]} style={{maxHeight: '30vh', objectFit: 'cover'}} className={carClasses} />
                <Card.Body>
                    <Card.Title><Row><Col>{car["brand"]} {car["model"]}</Col><Col className='text-end'>{car["price"]} €</Col></Row></Card.Title>
                    <Card.Text>
                        <Row>
                        <Col>
                        <ul className="no-bullets">
                            <li><span className="details-desc">Erstzulassung</span>{car["reg_month"]}/{car["reg_year"]}</li>
                            <li><span className="details-desc">Kilometer</span>{car["kilometers"]} km</li>
                            <li><span className="details-desc">Leistung</span>{car["power"]} PS</li>
                            <li><span className="details-desc">Treibstoff</span>{car["fuel"]}</li>
                            <li><span className="details-desc">Außenfarbe</span>{car["color"]}</li>
                        </ul>
                        </Col>
                        <Col>
                        <ul className="no-bullets">
                            <li><span className="details-desc">Antrieb</span>{car["drive"]}</li>
                            <li><span className="details-desc">Getriebe</span>{car["transmission"]}</li>
                            <li><span className="details-desc">Türen</span>{car["doors"]}</li>
                            <li><span className="details-desc">Sitze</span>{car["seats"]}</li>
                        </ul>
                        </Col>
                        </Row>
                    </Card.Text>
                    <Row className='text-center'>
                        {soldText}
                    </Row>
                    <Row className="text-center">
                        {buttons}
                    </Row>
                </Card.Body>
                </Card>
            </Col>
        )
    }
    
    function renderContainerContent(cars){
        let containerContent = []
        let i = 0
        while (i < cars.length) {
            containerContent.push(<Row className="p-3">{cars.slice(i,i+props.carsPerRow).map((car) => renderCarCard(car))}</Row>)
            i += props.carsPerRow
        }
        return containerContent
    }

    function renderFilterOptions(showArchive){
        if(showArchive === false){
            return <Button variant="primary" onClick={props.toggleArchive}>Show Archive</Button>
        } else {
            return <Button variant="outline-primary" onClick={props.toggleArchive}>Hide Archive</Button>
        }
    }

    useEffect(() => {
        if(props.logged_in === true){
            if(props.cars_fetched === false){
                props.fetchCars(props.showArchive)
            }
        } else{
            props.autoLogin()
        }
    })

    return(
        <Container>
            <Row className="pt-2">
                <Col className="text-end">
                    {renderFilterOptions(props.showArchive)}
                </Col>
            </Row>
            {/* <Button onClick={() => console.log(props)} variant="primary">Log Props</Button> */}
            {renderContainerContent(props.cars)}
        </Container>
    )
}

const mapStateToProps = state => ({
    cars: state.overview.cars,
    cars_fetched: state.overview.cars_fetched,
    showArchive: state.overview.showArchive,
    logged_in: state.login.logged_in,
})

const mapDispatchToProps = {
    fetchCars,
    editCar,
    setCarSold,
    setCarActive,
    archiveCar,
    toggleArchive,
    setCar,
    autoLogin
}
export default connect(mapStateToProps, mapDispatchToProps)(CarsContainer)