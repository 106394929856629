import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col"
import CarCards from "./CarCards";
import PublicNavbar from "./PublicNavbar";
import { useEffect } from "react";
import MyFooter from "./Footer";

function Home(props){
    // change background-color to black to prevent white stripes on scrolling to top or bottom
    useEffect(() => {document.body.style.backgroundColor = 'black'})
    return (
        <>
        <PublicNavbar />
        <Container fluid className="public-container text-center justify-content-center">
            <Container className="heading-container">
                <h1 className="heading">Unser aktueller Fahrzeugbestand</h1>
                <p className="sub-text">Diese Fahrzeuge sind nach unseren Qualitätsstandard geprüft und werden aktuell zum Verkauf angeboten.</p>
            </Container>
            <CarCards />
        </Container>
        <MyFooter />
        </>
    )
}
export default Home;