import './App.css';
import {Switch, Route, Router} from 'react-router-dom'
import Home from './components/Home'
import NewCar from './components/NewCar';
import Login from './components/Login';
import Overview from './components/Overview'
import { history } from './Store';
import { ConnectedRouter } from 'connected-react-router';

function App() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/newcar">
          <NewCar />
        </Route>
        <Route path="/cars">
          <Overview />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
}

export default App;
