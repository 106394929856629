import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/esm/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/esm/Button'
import Alert from 'react-bootstrap/esm/Alert'
import Spinner from 'react-bootstrap/esm/Spinner'
import {connect} from 'react-redux'
import {crawl, saveCar, setCrawlLink, addExtra, setExtra, updateCarValue, autoLogin, setLoading} from '../actions'
import Car from './data/Car'
import { useEffect } from 'react'

function InputContainer(props){

    // was crawl-button pressed? => show input-form when response was received
    function renderCarForm(car){

        function renderErrorMsg(){
            if(props.error_msg !== ""){
                return <Alert variant="danger">{props.error_msg}</Alert>
            } else {
                return null
            }
        }

        function handleAddExtra(e){
            e.preventDefault()
            props.addExtra(props.extra)
            props.setExtra("")
        }
    
        function ExtraKeyCheck(e){
            if(e.charCode === 13){
                e.preventDefault()
                handleAddExtra(e)
            }
        }
    
        function handleExtraChange(e){
            props.setExtra(e.target.value)
        }
        
        function renderSaveButton(){
            console.log(props)
            if(props.loading === true){
                return (
                <Button variant="primary" type="submit" disabled>
                    <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" className="me-2" />Speichern
                </Button>)
            }
            else {
                return (
                <Button variant="primary" type="submit">
                    Speichern
                </Button>)
            }
        }

        function handleSaveCar(e){
            e.preventDefault()
            props.setLoading(true)
            const formDataObj = Object.fromEntries(new FormData(e.target).entries())
            formDataObj["sold"] = false
            formDataObj["extras"] = props.car.extras
            formDataObj["thumbnail"] = props.car.thumbnail
            if (formDataObj["extra"] != "") {
                formDataObj["extras"].concat(formDataObj["extra"])
            }
            delete formDataObj.extra
            props.saveCar(formDataObj)
            //e.target.reset()
        }

        var extras = [<span>Extras</span>]
        if(car.extras.length === 0) {
            extras = [<span>Extras</span>]
        } else{
            extras.push(
            <ul>
                {car.extras.map((extra) => <li>{extra}</li>)}
            </ul>
            )
        }

        var thumbnail_container = [<span>Thumbnail</span>]
        if(car.thumbnail === ""){
            thumbnail_container = [<span>Thumbnail</span>]
        } else{
            thumbnail_container.push(
                <ul className="no-bullets">
                    <li><img src={car.thumbnail} className="thumbnail" /></li>
                </ul>
            )
        }

        var content = []
        //if(crawledcar === undefined){
            // return form without prefilling
            content.push(<Form onSubmit={handleSaveCar}>
                <fieldset disabled={props.loading}>
                <Form.Group controlID="formID" hidden>
                    <Form.Control type="text" defaultValue={car._id} name="_id"/>
                </Form.Group>
                <Row className="pt-lg-3">
                    <Col lg={6}>
                        <h1>Fahrzeug anlegen/bearbeiten</h1>
                    </Col>
                    <Col lg={6} className="text-end">
                        {renderSaveButton()}
                    </Col>
                </Row>
                <Row>
                    <Col>{renderErrorMsg()}</Col>
                </Row>
                <Row className="pt-lg-3">
                    <Col lg={2}>
                        <Form.Group controlID="formBrand" >
                            <Form.Label>Marke</Form.Label>
                            <Form.Control type="text" placeholder="Audi" defaultValue={car.brand} name="brand" required/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formModel" >
                            <Form.Label>Model</Form.Label>
                            <Form.Control type="text" placeholder="A4" defaultValue={car.model} name="model" required/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formPrice" >
                            <Form.Label>Preis</Form.Label>
                            <Form.Control type="number" placeholder="15999" defaultValue={car.price} name="price" required/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formRegMonth" >
                            <Form.Label>EZ (Monat)</Form.Label>
                            <Form.Control type="number" placeholder="02" defaultValue={car.reg_month} name="reg_month" required/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formRegYear" >
                            <Form.Label>EZ (Jahr)</Form.Label>
                            <Form.Control type="number" placeholder="2019" defaultValue={car.reg_year} name="reg_year" required/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formKilometer" >
                            <Form.Label>Kilometer</Form.Label>
                            <Form.Control type="number" placeholder="123456" defaultValue={car.kilometers} name="kilometers" required/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="pt-lg-3">
                    <Col lg={2}>
                        <Form.Group controlID="formPower" >
                            <Form.Label>Leistung</Form.Label>
                            <Form.Control type="number" placeholder="200" defaultValue={car.power} name="power"/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formFuel" >
                            <Form.Label>Treibstoff</Form.Label>
                            <Form.Control type="text" placeholder="Benzin" defaultValue={car.fuel} name="fuel"/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formColor" >
                            <Form.Label>Außenfarbe</Form.Label>
                            <Form.Control type="text" placeholder="Schwarz" defaultValue={car.color} name="color"/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formDrive" >
                            <Form.Label>Antrieb</Form.Label>
                            <Form.Control type="text" placeholder="Vorderrad" defaultValue={car.drive} name="drive"/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group controlID="formTransmission" >
                            <Form.Label>Getriebe</Form.Label>
                            <Form.Control type="text" placeholder="Schaltgetriebe" defaultValue={car.transmission} name="transmission"/>
                        </Form.Group>
                    </Col>
                    <Col lg={1}>
                        <Form.Group controlID="formSeats" >
                            <Form.Label>Sitze</Form.Label>
                            <Form.Control type="text" placeholder="5" defaultValue={car.seats} name="seats"/>
                        </Form.Group>
                    </Col>
                    <Col lg={1}>
                        <Form.Group controlID="formDoors" >
                            <Form.Label>Türen</Form.Label>
                            <Form.Control type="text" placeholder="5" defaultValue={car.doors} name="doors"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="pt-lg-3">
                    <Col lg={3}>
                        <Form.Group controlID="formInspectionMonth" >
                            <Form.Label>§57a gültig bis (Monat)</Form.Label>
                            <Form.Control type="number" placeholder="10" defaultValue={car.inspection_valid_month} name="inspection_valid_month" required/>
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <Form.Group controlID="formInspectionYear" >
                            <Form.Label>§57a gültig bis (Jahr)</Form.Label>
                            <Form.Control type="number" placeholder="2022" defaultValue={car.inspection_valid_year} name="inspection_valid_year" required/>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlID="formExtras" >
                            <Form.Label>Extras</Form.Label>
                            <Form.Control type="text" placeholder="ABS" value={props.extra} onChange={handleExtraChange} onKeyPress={ExtraKeyCheck} name="extra"/>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="align-self-end">
                        <Button variant="primary" onClick={handleAddExtra}>Extra hinzufügen</Button>
                    </Col>
                </Row>
                <Row className="pt-lg-3">
                    <Col lg={4}>
                        <Form.Group controlId="formDescription" >
                            <Form.Label>Beschreibung</Form.Label>
                            <Form.Control as="textarea" placeholder="Beschreibung" name="description" defaultValue={car.description} rows={4}/>
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        {thumbnail_container}
                    </Col>
                    <Col lg={2}>
                        {extras}
                    </Col>
                    <Col>
                        <Form.Group controlId="formLink">
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="url" placeholder="https://www.willhaben.at/iad/gebrauchtwagen/d/auto/xxxxxxx/" defaultValue={car.url || props.crawllink} name="url" />
                        </Form.Group>
                    </Col>
                </Row>
                </fieldset>
            </Form>)
        //}
        return content
    }

    function handleSubmit(e){
        e.preventDefault()
        props.crawl(props.crawllink)
    }

    function keyCheck(e){
        if(e.charCode === 13){
            e.preventDefault()
            handleSubmit(e)
        }
    }

    function handleChange(e){
        props.setCrawlLink(e.target.value)
    }

    function renderCrawlButton(){
        if(props.crawling){
            return (
                <Button variant="primary" type="submit" disabled>
                    <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" className="me-2" />Auslesen
                </Button>)
        } else {
            return (<Button variant ="outline-primary" onClick={handleSubmit} >Auslesen</Button>)
        }
    }

    return(
        <>
        <Container className="py-4">
            <Form className="pb-4">
                <fieldset disabled={props.crawling}>
                    <Row>
                        <Col className="text-end my-auto" lg={2}>Willhaben.at-Link</Col>
                        <Col lg={8}>
                            <Form.Group controlId="formLink">
                                <Form.Control type="url" placeholder="https://www.willhaben.at/iad/gebrauchtwagen/d/auto/xxxxxxx/" value={props.crawllink} onChange={handleChange} autoFocus onKeyPress={keyCheck} />
                            </Form.Group>
                        </Col>
                        <Col lg={2} className="my-auto">
                            {renderCrawlButton()}
                        </Col>
                    </Row>
                </fieldset>
            </Form>
            {renderCarForm(props.car)}
        </Container>
        </>
    )
}

const mapStateToProps = state => ({
    crawllink: state.input.crawllink,
    car: state.input.car,
    extra: state.input.extra,
    error_msg: state.input.error_msg,
    logged_in: state.login.logged_in,
    loading: state.input.loading,
    crawling: state.input.crawling
})

const mapDispatchToProps = {
    crawl,
    saveCar,
    setCrawlLink,
    addExtra,
    setExtra,
    updateCarValue,
    autoLogin,
    setLoading
}
export default connect(mapStateToProps, mapDispatchToProps)(InputContainer)