import { useEffect } from "react"
import LoginForm from "./LoginForm"
import MyNavbar from "./MyNavbar"


function Login(props) {
    return(
        <>
            <MyNavbar />
            <LoginForm />
        </>
    )
}
export default Login