import { combineReducers } from "redux";
import {overviewReducer} from './overviewReducer'
import { inputReducer } from "./inputReducer";
import { loginReducer } from "./loginReducer"
import {connectRouter} from 'connected-react-router'
import { publicReducer } from "./publicReducer";

/* const allReducers = combineReducers({
    router: connectRouter(history),
    overview: overviewReducer,
    input: inputReducer,
    login: loginReducer
}) */

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    overview: overviewReducer,
    input: inputReducer,
    login: loginReducer,
    public: publicReducer
})

export default createRootReducer