import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { setCrawlModal, backgroundCrawl } from "../actions";
import {connect} from 'react-redux';
import {history} from '../Store'

function CrawlingModal(props){

    function handleClose(){
        props.setCrawlModal(!props.show_crawl_modal)
    }

    function handleBackground(){
        props.backgroundCrawl(props.crawllink)
        props.setCrawlModal(!props.show_crawl_modal)
        history.push('/cars')
    }

    return(
        <Modal show={props.show_crawl_modal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>The Car is not crawlable at the moment.</Modal.Title>
            </Modal.Header>
                <Modal.Body>We are very sorry, but the car could not be crawled right now. Please try it again later!</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleBackground}>
                    Crawl in Background & Return
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => ({
    show_crawl_modal: state.input.show_modal,
    crawllink: state.input.crawllink
})

const mapDispatchToProps = {
    setCrawlModal,
    backgroundCrawl
}

export default connect(mapStateToProps, mapDispatchToProps)(CrawlingModal)