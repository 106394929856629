import { CARS_RECEIVED, TOGGLE_ARCHIVE } from "../actions";

const defaultData = []

const initialState = {
    cars: [],
    cars_fetched: false,
    showArchive: false
}

export function overviewReducer(state = initialState, action){
    switch(action.type) {
        case CARS_RECEIVED: 
            return {...state, cars: action.cars, cars_fetched: true}
        case TOGGLE_ARCHIVE:
            return {...state, showArchive: !state.showArchive, cars_fetched: false}
        default: return state
    }
}