import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {rootSaga} from './sagas'
import createRootReducer from './reducers'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

const sagaMiddleware = createSagaMiddleware()

//const defaultstate = {}

//const store = createStore(allReducers, applyMiddleware(sagaMiddleware))


export const history = createBrowserHistory();
const store = createStore(
    createRootReducer(history),
    {},
    compose(applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware)
    )
)

sagaMiddleware.run(rootSaga)

export default store