import Card from 'react-bootstrap/esm/Card'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'
import Container from 'react-bootstrap/esm/Container'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import {fetchPublicCars} from '../actions'

function CarCards(props){

    function formatPrice(price){
        const formatted = price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        return formatted
    }

    function renderCarCard(car){
        let content = []
        
        content.push(
            <Col lg={12} className="pb-sm-3 pb-md-3">
                <Card width="100%" className="card-black text-start">
                    <Row className="display-flex d-flex align-items-center">
                        <Col lg={3} md={3} sm={3} xs={3}>
                            <Card.Img src={car['thumbnail'] != "" ? car['thumbnail'] : "logo.png"} className="card-img img-fluid my-center"/>
                        </Col>
                        <Col>
                            <Card.Body>
                                <Row>
                                    <Col lg="auto" md="auto" sm="auto" xs="auto">
                                        <h3>{car['brand'] + " " + car['model']}</h3>
                                    </Col>
                                    <Col className="text-end">
                                        <h4>{formatPrice(car['price'])} €</h4>
                                    </Col>
                                </Row>
                                <Row className="detail-row" lg={4} md={4}>
                                    <Col xs={6}><span className="detail-desc">Marke</span></Col><Col xs={6}><span className="detail-val">{car['brand']}</span></Col>
                                    <Col xs={6}><span className="detail-desc">Modell</span></Col><Col xs={6}><span className="detail-val">{car['model']}</span></Col>
                                </Row>
                                <Row className="detail-row" lg={4} md={4}>
                                    <Col xs={6}><span className="detail-desc">Erstzulassung</span></Col><Col xs={6}><span className="detail-val">{car['reg_month'] + "/" + car['reg_year']}</span></Col>
                                    <Col xs={6}><span className="detail-desc">Kilometer</span></Col><Col xs={6}><span className="detail-val">{car['kilometers'] != "" ? car['kilometers'] : "-"} km</span></Col>
                                </Row>
                                <Row className="detail-row" lg={4} md={4}>
                                    <Col xs={6}><span className="detail-desc">Leistung</span></Col><Col xs={6}><span className="detail-val">{car['power'] != "" ? Math.round(car['power'] * 1.35962) : "-"} PS</span></Col>
                                    <Col xs={6}><span className="detail-desc">Antrieb</span></Col><Col xs={6}><span className="detail-val">{car['drive'] != "" ? car['drive'] : "-"}</span></Col>
                                </Row>
                                <Row className="detail-row" lg={4} md={4}>
                                    <Col xs={6}><span className="detail-desc">Getriebe</span></Col><Col xs={6}><span className="detail-val">{car['transmission'] != "" ? car['transmission'] : "-"}</span></Col>
                                    <Col xs={6}><span className="detail-desc">Treibstoff</span></Col><Col xs={6}><span className="detail-val">{car['fuel'] != "" ? car['fuel'] : "-"}</span></Col>
                                </Row>
                                <Row className="detail-row" lg={4} md={4}>
                                    <Col xs={6}><span className="detail-desc">Farbe</span></Col><Col xs={6}><span className="detail-val">{car['color'] != "" ? car['color'] : "-"}</span></Col>
                                    <Col xs={6}><span className="detail-desc"></span></Col><Col xs={6}><span className="detail-val"></span></Col>
                                </Row>
                                <Row>
                                    <Col className="text-end">
                                        <Button className="detail-button" href={car['url']} target="_blank">Weitere Details</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </Col>
        )

        return content
    }

    function renderNoCars(){
        return(
            <Container >
                <h2 className="heading">Leider sind aktuell alle unsere Fahrzeuge vergriffen.</h2>
                <p className="sub-text">Sie möchten ihr Auto verkaufen oder sind auf der Suche nach einem bestimmten Fahrzeug? Dann <a className="text-link" href="https://penz-automotive.at/" target="_self">kontaktieren</a> Sie uns.</p>
            </Container>
        )
    }

    function renderCards(cars){
        let content = []
        let i = 0
        if(cars.length == 0){
            return renderNoCars()
        }
        while (i < (cars.length)){
            content.push(<Row className="pt-4">{cars.slice(i,i+2).map((car) => renderCarCard(car))}</Row>)
            i += 2
        }
        return content
    }

    useEffect(() => {
        props.fetchPublicCars()
    },[])

    return(
        <>
            {renderCards(props.cars)}
        </>
    )
}

const mapStateToProps = state => ({
    cars: state.public.cars
})

const mapDispatchToProps = {
    fetchPublicCars
}

export default connect(mapStateToProps, mapDispatchToProps) (CarCards)