import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

function PublicNavbar(props){
    return (
        <Navbar expand="lg" className="navbar-dark" sticky="top">
            <Container fluid className="navbar-logo-container" style={{ backgroundColor: 'black' }}>
                <Navbar.Brand href="https://penz-automotive.at/">
                    <img
                        src="/logo.png"
                        height="100em"
                        className="d-inline-block align-top navbar-logo"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end text-center">
                <div className="menu-item-container text-center">
                <div class="top-animation" />
                <Nav.Link className="navbar-link" href="https://penz-automotive.at/" target="_self">
                    Home
                </Nav.Link>
                </div>
                <div className="menu-item-container text-center">
                <div class="top-animation" />
                <Nav.Link className="navbar-link" href="https://penz-automotive.at/fahrzeugankauf" target="_self">Fahrzeugankauf</Nav.Link>
                </div>
                <div className="menu-item-container menu-item-container-active text-center">
                <div class="top-animation" />
                <Nav.Link className="navbar-link" href="/" target="_self">Fahrzeugbestand</Nav.Link>
                </div>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default PublicNavbar;
