import Container from "react-bootstrap/esm/Container"
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'

function MyFooter(props){
    return (
        <Container fluid className="footer m-4">
            <Row>
                <Col className="text-center">&copy; {new Date().getFullYear()} | Made with 🤍 by <a href="https://www.tlumesberger.at" target="_blank" className="text-link">tlumesberger</a></Col>
            </Row>
        </Container>
    )
}
export default MyFooter;