import { useEffect } from "react"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import Form from 'react-bootstrap/esm/Form'
import Button from 'react-bootstrap/esm/Button'
import Alert from 'react-bootstrap/esm/Alert'
import {connect} from 'react-redux'
import {login} from '../actions'

function LoginForm(props){
    
    function renderErrorMsg(){
        if(props.error_msg !== ""){
            return <Alert variant="danger">{props.error_msg}</Alert>
        } else {
            return null
        }
    }

    function handleSubmit(e){
        e.preventDefault()
        const formDataObj = Object.fromEntries(new FormData(e.target).entries())
        props.login(formDataObj["username"], formDataObj["password"])
    }
    
    return(
        <Container fluid className="d-flex flex-column min-vh-100 justify-content-center align-items-center bg-grey">
            <Container className="login-form-container">
                <Row className="text-center">
                    <Col><h3>Login</h3></Col>
                </Row>
                <Row><Col>
                {renderErrorMsg()}
                </Col></Row>
                <Form onSubmit={handleSubmit}>
                    <Row className="pb-2">
                        <Col>
                            <Form.Group controlId="formLoginUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Username" name="username"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        <Col>
                            <Form.Group controlId="formLoginPassword">
                                <Form.Label>Passwort</Form.Label>
                                <Form.Control type="password" placeholder="Password" name="password"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <Button variant="primary" type="submit" >
                                Login
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Container>

    )
}

const mapStateToProps = state => ({
    username: state.login.username,
    password: state.login.password,
    error_msg: state.login.error_msg
})

const mapDispatchToProps = {
    login
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)