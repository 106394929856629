import { ADD_EXTRA, CRAWLED_CAR_RECEIVED, SET_EXTRA, SET_CRAWL_LINK, UPDATE_CAR_VALUE, SET_CAR, 
    SET_CAR_INPUT_ERROR_MSG, SET_LOADING , SET_CRAWLING, SET_CRAWL_MODAL} from "../actions";
import Car from "../components/data/Car"

const initialState = {
    crawllink: "",
    car: new Car(),
    extra: "",
    error_msg: "",
    crawling: false,
    loading: false,
    show_modal: false
}

export function inputReducer(state = initialState, action){
    switch(action.type){
        case SET_CRAWL_LINK: return {...state, crawllink: action.link}
        case CRAWLED_CAR_RECEIVED: return {...state, car: action.car}
        case ADD_EXTRA: return {...state, car: {...state.car, extras: [...state.car.extras, action.extra]}}
        case SET_EXTRA: return {...state, extra: action.extra}
        case UPDATE_CAR_VALUE: return {...state, car: {...state.car, [action.key]: action.value}}
        case SET_CAR: return {...state, car: action.car}
        case SET_CAR_INPUT_ERROR_MSG: return {...state, error_msg: action.msg}
        case SET_LOADING: return {...state, loading: action.val}
        case SET_CRAWLING: return {...state, crawling: action.val}
        case SET_CRAWL_MODAL: return {...state, show_modal: action.val}
        default: return state
    }
}