import {all, put, takeLatest} from 'redux-saga/effects'
import {FETCH_CARS, CARS_RECEIVED, CRAWL_LINK, CRAWLED_CAR_RECEIVED, SET_CRAWL_LINK, SAVE_CAR, SET_CAR, SET_CAR_SOLD, SET_CAR_ACTIVE, LOGIN, SET_LOGIN_ERROR_MSG, SET_LOGGED_IN, SET_CAR_INPUT_ERROR_MSG, AUTO_LOGIN, FETCH_PUBLIC_CARS, SET_PUBLIC_CARS, SET_LOADING, SET_CRAWLING, ARCHIVE_CAR, SET_CRAWL_MODAL, BACKGROUND_CRAWL} from '../actions'
import axios from 'axios'
import { SERVER_ADDRESS } from '../Defines'
import Car from '../components/data/Car'
import {getJWTHeader} from './helperFunctions'
import { push } from 'connected-react-router'

export function* fetchCars(action){
    var result = yield (axios.post(SERVER_ADDRESS + '/fetchCars', {"archive": action.val}, getJWTHeader()))
    yield put({type: CARS_RECEIVED, cars: result.data.cars})
}

export function* crawlCar(action){
    yield put({type: SET_CRAWLING, val: true})
    try{
        var result = yield (axios.post(SERVER_ADDRESS + '/crawl', {"link": action.link}, getJWTHeader()))
        var car = JSON.parse(result.data.car)
        car = Object.assign(new Car, car)
        yield put({type: CRAWLED_CAR_RECEIVED, car: car})
    } catch (error){
        const errorCode = error.response.status
        if (errorCode === 409){
            yield put({type: SET_CRAWL_MODAL, val: true})
        } else {
            console.log("Error occured: ", error)
        }
    }
    finally {
        yield put({type: SET_CRAWLING, val: false})
    }
}

export function* startBackgroundCrawl(action){
    yield (axios.post(SERVER_ADDRESS + '/crawlbackground', {"link": action.link}, getJWTHeader()))
}

export function* saveCar(action){
    var result = yield (axios.post(SERVER_ADDRESS + '/saveCar', {"car": action.car}, getJWTHeader()))
    if(result.data.success === true){
        // show success-msg
        // reset Car-state
        yield put({type: SET_CAR, car: new Car()})
        // reset Crawl-Link
        yield put({type: SET_CRAWL_LINK, link: ""})
    } else {
        yield put({type: SET_CAR_INPUT_ERROR_MSG, msg: result.data.msg})
    }
    yield put({type: SET_LOADING, val: false})
}

export function* soldCar(action){
    yield (axios.post(SERVER_ADDRESS + '/soldCar', {"car_id": action.id}, getJWTHeader()))
    yield put({type: FETCH_CARS})
}

export function* unsellCar(action){
    yield (axios.post(SERVER_ADDRESS + '/unsellCar', {"car_id": action.id}, getJWTHeader()))
    yield put({type: FETCH_CARS})
}

export function* archiveCar(action){
    yield (axios.post(SERVER_ADDRESS + '/archivecar', {"car_id": action.id}, getJWTHeader()))
    yield put({type: FETCH_CARS})
}

export function* watchForFetchCars(){
    yield takeLatest(FETCH_CARS, fetchCars)
}

export function* watchForCrawlCar(){
    yield takeLatest(CRAWL_LINK, crawlCar)
}

export function* watchForSaveCar(){
    yield takeLatest(SAVE_CAR, saveCar)
}

export function* watchForCarSold(){
    yield takeLatest(SET_CAR_SOLD, soldCar)
}

export function* watchForCarActive(){
    yield takeLatest(SET_CAR_ACTIVE, unsellCar)
}

export function* watchForArchiveCar(){
    yield takeLatest(ARCHIVE_CAR, archiveCar)
}

export function* watchForBackgroundCrawl(){
    yield takeLatest(BACKGROUND_CRAWL, startBackgroundCrawl)
}

export function* sendLogin(action){
    yield put({type: SET_LOGIN_ERROR_MSG, msg:""})
    var result = yield (axios.post(SERVER_ADDRESS + '/login', {"username": action.username, "password": action.password}))
    result = result.data
    if (result["login_successful"]) {
        if(result["token"] !== undefined){
            localStorage.setItem("jwt-token", result["token"])
            yield put({type: AUTO_LOGIN})
        }
        yield put({type: SET_LOGIN_ERROR_MSG, msg: ""})
        yield put({type: SET_LOGGED_IN, val: true})
        localStorage.setItem('logged_in', true)
        // successful login -> Forward to overview-page
        yield put(push('/cars'))
    } else {
        yield put({type: SET_LOGIN_ERROR_MSG, msg: result["msg"]})
        localStorage.setItem('logged_in', false)
    }
}

export function* watchForLogin(){
    yield takeLatest(LOGIN, sendLogin)
}

export function* sendAutoLogin(){
    try{
        var result = yield (axios.post(SERVER_ADDRESS + '/autologin', {}, getJWTHeader()))
        if(result.status === 200 && result.data["login_successful"] == true){
            yield put({type: SET_LOGGED_IN, val: true})
            localStorage.setItem('logged_in', true)
            yield put(push('/cars'))
        } else {
            // if the autologin did not work we should show some error message and forward to the login
            yield put(push('/login'))
            localStorage.setItem('logged_in', false)
        }
    } catch{
        yield put(push('/login'))
    }
}

export function* watchForAutoLogin(){
    yield takeLatest(AUTO_LOGIN, sendAutoLogin)
}

export function* fetchPublicCars(){
    var result = yield(axios.get(SERVER_ADDRESS + '/fetchpubliccars'))
    yield put({type: SET_PUBLIC_CARS, cars: result.data.cars})
}

export function* watchForFetchPublicCars(){
    yield takeLatest(FETCH_PUBLIC_CARS, fetchPublicCars)
}

export function* rootSaga() {
    yield all([
        watchForFetchCars(),
        watchForCrawlCar(),
        watchForSaveCar(),
        watchForCarSold(),
        watchForCarActive(),
        watchForArchiveCar(),
        watchForBackgroundCrawl(),
        // watch-functions for login
        watchForLogin(),
        watchForAutoLogin(),
        // watch-functions for public
        watchForFetchPublicCars(),
    ])
}