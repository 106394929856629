/**
 * action types for 
 */

export const FETCH_CARS = 'fetch_cars'
export const CARS_RECEIVED = 'cars_received'
export const SET_CRAWL_LINK = 'set_crawl_link'
export const CRAWL_LINK = 'crawl_link'
export const CRAWLED_CAR_RECEIVED = 'crawled_car_received'
export const BACKGROUND_CRAWL = 'background_crawl'
export const SAVE_CAR = 'save_car'
export const ADD_EXTRA = 'add_extra'
export const SET_EXTRA = 'set_extra'
export const UPDATE_CAR_VALUE = 'update_car_value'
export const SET_CAR = 'set_car'
export const SET_CAR_SOLD = 'set_car_sold'
export const SET_CAR_ACTIVE = 'set_car_active'
export const ARCHIVE_CAR = 'archive_car'
export const TOGGLE_ARCHIVE = 'toggle_archive'
export const EDIT_CAR = 'edit_car'
export const SET_CAR_INPUT_ERROR_MSG = 'set_car_input_error_msg'
export const SET_LOADING = 'set_loading'
export const SET_CRAWLING = 'set_crawling'
export const SET_CRAWL_MODAL = 'set_crawl_modal'

export function crawl(link){
    return {
        type: CRAWL_LINK,
        link: link
    }
}

export function backgroundCrawl(link){
    return {
        type: BACKGROUND_CRAWL,
        link: link
    }
}

export function setCrawlLink(link){
    return {
        type: SET_CRAWL_LINK,
        link: link
    }
}

export function saveCar(car){
    return {
        type: SAVE_CAR,
        car: car
    }
}

export function fetchCars(val){
    return {
        type: FETCH_CARS,
        val: val
    }
}

export function addExtra(extra){
    return {
        type: ADD_EXTRA,
        extra: extra
    }
}

export function setExtra(extra){
    return {
        type: SET_EXTRA,
        extra: extra
    }
}

export function updateCarValue(key, value){
    return {
        type: UPDATE_CAR_VALUE,
        key: key,
        value: value
    }
}

export function setCar(car){
    return {
        type: SET_CAR,
        car: car
    }
}

export function setCarSold(id){
    return {
        type: SET_CAR_SOLD,
        id: id
    }
}

export function setCarActive(id){
    return{
        type: SET_CAR_ACTIVE,
        id: id
    }
}

export function archiveCar(id){
    return{
        type: ARCHIVE_CAR,
        id: id
    }
}

export function toggleArchive(){
    return {
        type: TOGGLE_ARCHIVE
    }
}

export function editCar(id){
    return{
        type: EDIT_CAR,
        id: id
    }
}

export function setLoading(val){
    return {
        type: SET_LOADING,
        val: val
    }
}

export function setCrawling(val){
    return {
        type: SET_CRAWLING,
        val: val
    }
}

export function setCrawlModal(val){
    return {
        type: SET_CRAWL_MODAL,
        val: val
    }
}

/**
 * actions for login
 */

export const LOGIN = "login"

export function login(username, password){
    return{
        type: LOGIN,
        username: username,
        password: password,
    }
}

export const AUTO_LOGIN = "auto_login"

export function autoLogin(){
    return{
        type: AUTO_LOGIN
    }
}

export const SET_LOGIN_ERROR_MSG = "set_login_error_msg"
export const SET_LOGGED_IN = 'set_logged_in'

/**
 * actions for public
 */

export const SET_PUBLIC_CARS = "public_cars_received"
export const FETCH_PUBLIC_CARS = "fetch_public_cars"

export function fetchPublicCars(){
    return{
        type: FETCH_PUBLIC_CARS
    }
}