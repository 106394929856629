import MyNavbar from "./MyNavbar";
import InputContainer from "./InputContainer";
import {connect, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom'
import { useEffect } from "react";
import { autoLogin } from "../actions";
import CrawlingModal from "./CrawlingModal";

function NewCar(props){

    useEffect(() => {
        if(props.logged_in === false){
            props.autoLogin()
        }
    },[])

    const logged_in = useSelector((state) => state.login.logged_in)
    console.log(logged_in)
    if(logged_in === false){
        return(
            <Redirect to="/login" />
        )
    }
    return(
        <>
        <CrawlingModal />
        <MyNavbar />
        <InputContainer />
        </>
    )
}

const mapStateToProps = state => ({
    logged_in: state.login.logged_in,
})

const mapDispatchToProps = {
    autoLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCar)
