import React from 'react'

const defaults = {
    _id: 0,
    brand: "",
    model: "",
    price: "",
    reg_month: "",
    reg_year: "",
    inspection_valid_month: "",
    inspection_valid_year: "",
    kilometers: "",
    power: "",
    fuel: "",
    color: "",
    drive: "",
    transmission: "",
    seats: "",
    doors: "",
    extras: [],
    description: "",
    thumbnail: ""
}

class Car {
    constructor(options){
        if(options === undefined){
            this._id = defaults._id
            this.brand = defaults.brand
            this.model = defaults.model
            this.price = defaults.price
            this.reg_month = defaults.reg_month
            this.reg_year = defaults.reg_year
            this.inspection_valid_month = defaults.inspection_valid_month
            this.inspection_valid_year = defaults.inspection_valid_year
            this.kilometers = defaults.kilometers
            this.power = defaults.power
            this.fuel = defaults.fuel
            this.color = defaults.color
            this.drive = defaults.drive
            this.transmission = defaults.transmission
            this.seats = defaults.seats
            this.doors = defaults.doors
            this.extras = defaults.extras
            this.description = defaults.description
            this.thumbnail = defaults.thumbnail
        } else {
            this._id = options._id || defaults._id
            this.brand = options.brand || defaults.brand
            this.model = options.model || defaults.model
            this.price = options.price || defaults.price
            this.reg_month = options.reg_month || defaults.reg_month
            this.reg_year = options.reg_year || defaults.reg_year
            this.inspection_valid_month = options.inspection_valid_month || defaults.inspection_valid_month
            this.inspection_valid_year = options.inspection_valid_year || defaults.inspection_valid_year
            this.kilometers = options.kilometers || defaults.kilometers
            this.power = options.power || defaults.power
            this.fuel = options.fuel || defaults.fuel
            this.color = options.color || defaults.color
            this.drive = options.drive || defaults.drive
            this.transmission = options.transmission || defaults.transmission
            this.seats = options.seats || defaults.seats
            this.doors = options.doors || defaults.doors
            this.extras = options.extras || defaults.extras
            this.description = options.description || defaults.description
            this.thumbnail = options.thumbnail || defaults.thumbnail
        }
    }
}
export default Car