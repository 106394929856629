import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'

function MyNavbar(props){
    return (
        <Navbar expand="lg" bg="dark" className="px-4" sticky="top">
            <Navbar.Brand className="text-white" href="/">Fahrzeugverwaltung</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link className="text-white" href="/cars">Übersicht Fahrzeuge</Nav.Link>
            </Nav>
            <Nav>
                <Button className="mx-3" variant="outline-primary" href="/newcar">Neues Auto</Button>
                <Button variant="outline-danger" href="https://www.penz-automotive.at" target="_self">Zur Webseite</Button>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
export default MyNavbar;